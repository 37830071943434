<template>
  <div class="levelOneDiv1" style="background-color: #fff;">
    <div ref="tablebox1" class="table_box1">
      <div class="small_title">
        <templateTitle :title="title" :isBack="true" @handleBack="btnClose"></templateTitle>

        <el-button v-if="type == 'view'" size="mini" type="primary" plain icon="el-icon-edit" @click="editCols"
          >编辑</el-button
        >
      </div>

      <div v-if="type == 'add'">
        <el-form
          :inline="true"
          :model="searchObj"
          :rules="rules"
          ref="form"
          style="align-items: center;margin-bottom: 4px;"
          class="user-search flex-between"
          @submit.native.prevent
          label-position="top"
        >
          <div>
            <el-form-item label="新增年度" prop="year">
              <budgetYear ref="budgetYear" @refreshYear="refreshYear" />
            </el-form-item>
          </div>
        </el-form>
      </div>

      <div v-if="type == 'add'" class="small_title">
        <templateDivider style="width:94%" title="中央水库移民扶持基金支出进度"></templateDivider>
        <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="addCols">增加</el-button>
      </div>

      <el-row>
        <!-- 纵向表头设计 -->
        <el-col :span="5" style="background-color: #F9FAFE;">
          <el-row>
            <div class="leftTitletStyle">操作</div>
          </el-row>

          <el-row>
            <div class="leftTitletStyle">行政区域</div>
          </el-row>

          <!-- 下达资金 -->
          <el-row>
            <el-col :span="4" class="title_view4">
              <div class="firstTitletStyle">下达资金</div>
            </el-col>
            <el-col :span="20">
              <el-row>
                <el-col :span="24">
                  <div class="leftTitletStyle">（万元）</div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="4" class="title_view3">
              <div class="firstTitletStyle">
                按实际支付完成情况统计（个）
              </div>
            </el-col>
            <el-col :span="20">
              <el-row>
                <el-col :span="8" class="">
                  <div class="leftTitletStyle">其中：直补资金</div>
                </el-col>
                <el-col :span="16">
                  <el-row>
                    <div class="leftTitletStyle">下达数目（万元）</div>
                  </el-row>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="8" class="title_view4">
                  <div class="leftTitletStyle">项目资金</div>
                </el-col>
                <el-col :span="16">
                  <el-row>
                    <div class="leftTitletStyle">下达数目（万元）</div>
                  </el-row>
                  <el-row>
                    <div class="leftTitletStyle">支付数目（万元）</div>
                  </el-row>

                  <el-row>
                    <div class="leftTitletStyle">支付比例（%）</div>
                  </el-row>

                  <el-row>
                    <div class="leftTitletStyle">截止时间</div>
                  </el-row>
                </el-col>
              </el-row>
            </el-col>
          </el-row>

          <!-- 前期工作 -->
          <el-row>
            <el-col :span="4" class="title_view2">
              <div class="firstTitletStyle">前期工作</div>
            </el-col>
            <el-col :span="20">
              <el-row>
                <el-col :span="24">
                  <div class="leftTitletStyle">总个数</div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <div class="leftTitletStyle">行政立项已完成批复（个）</div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <div class="leftTitletStyle">实施方案已完成批复（个）</div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>

          <!-- 项目完成情况 -->
          <el-row>
            <el-col :span="4" class="title_view1">
              <div class="firstTitletStyle">项目完成情况</div>
            </el-col>

            <el-col :span="20">
              <el-row>
                <el-col>
                  <div class="leftTitletStyle">完成数（个）</div>
                </el-col>
              </el-row>

              <el-row>
                <el-col>
                  <div class="leftTitletStyle">移民美丽家园建设项目（个）</div>
                </el-col>
              </el-row>

              <el-row>
                <el-col>
                  <div class="leftTitletStyle">产业扶持项目（个）</div>
                </el-col>
              </el-row>

              <el-row>
                <el-col>
                  <div class="leftTitletStyle">就业创业能力培训（人次）</div>
                </el-col>
              </el-row>

              <el-row>
                <el-col>
                  <div class="leftTitletStyle">其他项目（个）</div>
                </el-col>
              </el-row>

              <el-row>
                <el-col>
                  <div class="leftTitletStyle">中小微水利工程建设及维护项目（个）</div>
                </el-col>
              </el-row>

              <el-row>
                <el-col>
                  <div class="leftTitletStyle">安全饮水及功能提升项目（个）</div>
                </el-col>
              </el-row>

              <el-row>
                <el-col>
                  <div class="leftTitletStyle">完成验收数（个）</div>
                </el-col>
              </el-row>

              <el-row>
                <el-col>
                  <div class="leftTitletStyle">完成投资（万元）</div>
                </el-col>
              </el-row>

              <el-row>
                <el-col>
                  <div class="leftTitletStyle">完成投资比例（%）</div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>

          <el-row>
            <div class="leftTitletStyle1">备注</div>
          </el-row>
        </el-col>
        <el-col :span="2" style="background-color: #F9FAFE;">
          <el-row>
            <div class="leftTotalStyle">合计</div>
          </el-row>

          <el-row>
            <div class="leftTotalStyle"></div>
          </el-row>

          <el-row>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('totalFunding') }}</div>
            </div>
          </el-row>

          <el-row>
            <el-col>
              <div class="leftTotalStyle">
                <div>{{ calculateTheTotal('directSubsidyFunding') }}</div>
              </div>
            </el-col>
          </el-row>

          <el-row>
            <el-col>
              <div class="leftTotalStyle">
                <div>{{ calculateTheTotal('projectFundingAllocation') }}</div>
              </div>
            </el-col>
          </el-row>

          <el-row>
            <el-col>
              <div class="leftTotalStyle">
                <div>{{ calculateTheTotal('projectFundingPayment') }}</div>
              </div>
            </el-col>
          </el-row>
          <!-- 支付比例 -->
          <el-row>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('projectPaymentRatio') }}</div>
            </div>
          </el-row>

          <el-row>
            <div class="leftTotalStyle"></div>
          </el-row>

          <el-row>
            <el-col>
              <div class="leftTotalStyle">
                <div>{{ calculateTheTotal('totalCount') }}</div>
              </div>
            </el-col>
          </el-row>

          <el-row>
            <el-col>
              <div class="leftTotalStyle">
                <div>{{ calculateTheTotal('administrativeApprovalCount') }}</div>
              </div>
            </el-col>
          </el-row>

          <el-row>
            <el-col>
              <div class="leftTotalStyle">
                <div>{{ calculateTheTotal('implementationPlanApprovalCount') }}</div>
              </div>
            </el-col>
          </el-row>

          <el-row>
            <el-col>
              <div class="leftTotalStyle">
                <div>{{ calculateTheTotal('completionCount') }}</div>
              </div>
            </el-col>
          </el-row>

          <el-row>
            <el-col>
              <div class="leftTotalStyle">
                <div>{{ calculateTheTotal('beautifulHomesteadProjectsCount') }}</div>
              </div>
            </el-col>
          </el-row>

          <el-row>
            <el-col>
              <div class="leftTotalStyle">
                <div>{{ calculateTheTotal('industrySupportProjectsCount') }}</div>
              </div>
            </el-col>
          </el-row>

          <el-row>
            <el-col>
              <div class="leftTotalStyle">
                <div>{{ calculateTheTotal('employmentTrainingParticipants') }}</div>
              </div>
            </el-col>
          </el-row>

          <el-row>
            <el-col>
              <div class="leftTotalStyle">
                <div>{{ calculateTheTotal('otherProjectsCount') }}</div>
              </div>
            </el-col>
          </el-row>

          <el-row>
            <el-col>
              <div class="leftTotalStyle">
                <div>{{ calculateTheTotal('smallWaterConservationProjectsCount') }}</div>
              </div>
            </el-col>
          </el-row>

          <el-row>
            <el-col>
              <div class="leftTotalStyle">
                <div>{{ calculateTheTotal('safeDrinkingWaterProjectsCount') }}</div>
              </div>
            </el-col>
          </el-row>

          <el-row>
            <el-col>
              <div class="leftTotalStyle">
                <div>{{ calculateTheTotal('completionAcceptanceCount') }}</div>
              </div>
            </el-col>
          </el-row>

          <el-row>
            <el-col>
              <div class="leftTotalStyle">
                <div>{{ calculateTheTotal('completionInvestment') }}</div>
              </div>
            </el-col>
          </el-row>

          <!-- 完成投资比例 -->
          <el-row>
            <el-col>
              <div class="leftTotalStyle">
                <div>{{ calculateTheTotal('completionInvestmentRatio') }}</div>
              </div>
            </el-col>
          </el-row>

          <el-row>
            <el-col>
              <div class="leftTotalStyle1"></div>
            </el-col>
          </el-row>
        </el-col>

        <!-- 2.纵向数据展示 -->
        <el-col :span="17">
          <div>
            <!-- -纵列数据遍历 -->
            <div :style="boxWidth" class="clearFloat">
              <div>
                <el-row class="flex">
                  <el-row style="width: 150px;" v-for="(col, index) in listData" :key="col.id">
                    <el-col :span="24">
                      <el-row v-if="type == 'add'">
                        <div class="leftTitletStyle">
                          <el-link type="danger" @click="removeCol(index)">删除</el-link>
                        </div>
                      </el-row>
                      <el-row v-if="type != 'add'">
                        <div class="leftTitletStyle"></div>
                      </el-row>

                      <el-col>
                        <template v-for="key1 in Object.keys(col)">
                          <el-row v-if="key1 == 'countyId'">
                            <div class="contentStyle">
                              <el-cascader
                                :append-to-body="false"
                                v-if="type == 'add' || type == 'edit'"
                                v-model="col[key1]"
                                size="mini"
                                :options="levalRegion"
                                :props="treeProps"
                                @change="handleChange($event, index)"
                                :show-all-levels="false"
                                placeholder="请选择"
                              >
                              </el-cascader>
                              <div class="box_view" v-else>
                                {{ col['county'] }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'totalFunding'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                                  .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                                  .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                                  .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                                "
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'directSubsidyFunding'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                                  .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                                  .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                                  .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                                "
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'projectFundingAllocation'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                                  .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                                  .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                                  .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                                "
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'projectFundingPayment'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                                  .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                                  .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                                  .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                                "
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <!-- 率 -->
                          <el-row v-if="key1 == 'projectPaymentRatio'">
                            <div class="contentStyle">
                              <div class="box_view">{{ formatRate('projectPaymentRatio', index) }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'projectFundingDeadline'">
                            <div class="contentStyle">
                              <el-date-picker
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                v-model="col[key1]"
                                type="date"
                                format="yyyy-MM-DD"
                                style="width: 100%"
                                placeholder="请选择"
                              >
                              </el-date-picker>

                              <div v-else class="box_view">
                                {{ col[key1] ? timeFormate(col[key1], 'YYYY-MM-DD') : '--' }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'totalCount'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{
                                  (Number(col['administrativeApprovalCount']) * 1000 +
                                    Number(col['implementationPlanApprovalCount']) * 1000) /
                                    1000
                                }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'administrativeApprovalCount'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'implementationPlanApprovalCount'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'completionCount'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'beautifulHomesteadProjectsCount'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'industrySupportProjectsCount'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'employmentTrainingParticipants'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'otherProjectsCount'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'smallWaterConservationProjectsCount'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'safeDrinkingWaterProjectsCount'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'completionAcceptanceCount'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'completionInvestment'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                                  .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                                  .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                                  .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                                "
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'completionInvestmentRatio'">
                            <div class="contentStyle">
                              <div class="box_view">{{ formatRate('completionInvestmentRatio', index) }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'remarks'">
                            <div class="contentStyle1">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="textarea"
                                resize="none"
                                :rows="10"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == '' ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>
                        </template>
                      </el-col>
                    </el-col>
                  </el-row>
                </el-row>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <div class="import_opration" v-if="type == 'add' || type == 'edit'">
        <el-button size="small" plain @click="btnClose()">取消</el-button>
        <el-button type="primary" size="small" @click="submitForm()">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import templateTitle from '@/components/templateView/templateTitle.vue'
import templateDivider from '@/components/templateView/templateDivider.vue'
import budgetYear from '@/components/templateView/budgetYear.vue'
import { _getLevelRegion } from '@/api/keepHome'

import {
  _ProgressReportAdd,
  _ProgressReportInfo,
  _ProgressReportEdit
} from '@/api/modular/postImmigrationService/expenditureProgress/supportFund.js'

export default {
  data() {
    return {
      type: '',
      row: {},
      title: '',
      input: '',
      listData: [],
      searchObj: {
        year: ''
      },
      rules: {
        year: [{ required: true, message: '请选择年度', trigger: 'blur' }]
      },
      loading: false,
      options: [],
      treeProps: { label: 'name', value: 'id', children: 'childRegions', checkStrictly: true },
      levalRegion: []
    }
  },
  // 注册组件
  components: {
    templateTitle,
    templateDivider,
    budgetYear
  },

  created() {
    this.getLevelRegion()

    this.$nextTick(() => {
      let box1 = document.querySelector('html').getBoundingClientRect().width
      console.log(box1)
      // this.boxWidth = `width:${box1 - 150}px !important;overflow-x: auto`
      // this.boxWidth1 = `width:${box1 - 150}px !important;`
    })
  },
  mounted() {},
  computed: {
    boxWidth() {
      let num = 150 * this.listData.length
      return `width:${num}px`
    }
  },

  methods: {
    handleChange(value, index) {
      let obj = this.getPids(value)
      this.listData[index].countyId = obj.id
      this.listData[index].county = obj.name
      this.listData[index].countyPids = obj.pids
      this.listData[index].orderNo = obj.orderNo
    },
    // 获取选中节点的pids值
    getPids(selectedOptions) {
      const selectedNode = this.findNodeById(this.levalRegion, selectedOptions[selectedOptions.length - 1])
      return selectedNode ? selectedNode : []
    },

    // 在数据源中查找指定ID的节点
    findNodeById(nodes, id) {
      for (const node in nodes) {
        if (nodes[node].id === id) {
          return nodes[node]
        }
        if (nodes[node].childRegions && nodes[node].childRegions.length > 0) {
          const foundNode = this.findNodeById(nodes[node].childRegions, id)
          if (foundNode) {
            return foundNode
          }
        }
      }
      return null
    },
    getLevelRegion() {
      let params = {
        startLevel: 2,
        endLevel: 3,
        inclusionLevel: true
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.levalRegion = this.formatTreeData(res.data)
        }
      })
    },
    formatTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].childRegions.length < 1) {
          data[i].childRegions = undefined
        } else {
          this.formatTreeData(data[i].childRegions)
        }
      }
      return data
    },
    calculateTheTotal(name) {
      if (name == 'totalCount') {
        let sum = 0
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          sum +=
            (Number(ele['administrativeApprovalCount']) * 100 + Number(ele['implementationPlanApprovalCount']) * 100) /
            100
        }

        // this.listData[index][name] = sum
        return sum
      } else if (name == 'projectPaymentRatio') {
        // 定义一个变量保存上一次的sum值
        let sum = 0
        let prevSum = 0
        // 遍历listData数组
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          // 当前sum值计算
          let currentSum = (Number(ele['projectFundingPayment']) / Number(ele['projectFundingAllocation'])) * 100
          // 如果当前sum值为NaN或Infinity，则使用上一次的sum值
          if (Number.isNaN(currentSum) || isFinite(currentSum)) {
            sum += Number.isNaN(currentSum) ? 0 : currentSum
          } else {
            sum = prevSum
          }
          // 更新上一次的sum值
          prevSum = sum
        }
        return prevSum.toFixed(2) + '%'
      } else if (name == 'completionInvestmentRatio') {
        // 定义一个变量保存上一次的sum值
        let sum = 0
        let prevSum = 0
        // 遍历listData数组
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          // 当前sum值计算
          let currentSum = (Number(ele['completionInvestment']) / Number(ele['totalFunding'])) * 100
          // 如果当前sum值为NaN或Infinity，则使用上一次的sum值
          if (Number.isNaN(currentSum) || isFinite(currentSum)) {
            sum += Number.isNaN(currentSum) ? 0 : currentSum
          } else {
            sum = prevSum
          }
          // 更新上一次的sum值
          prevSum = sum
        }
        return prevSum.toFixed(2) + '%'
      } else {
        let sum = 0
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          sum += Number(ele[name])
        }
        return sum
      }
    },
    refreshYear(year) {
      this.searchObj.year = year
    },
    add(type, row) {
      this.type = type
      this.row = row
      // let obj = {
      //   year: this.searchObj.year, //年份
      //   countyId: '总计', //县（市区）
      //   county: '', //县（市区）
      //   orderNo: null,
      //   totalFunding: null,
      //   directSubsidyFunding: null,
      //   projectFundingAllocation: null,
      //   projectFundingPayment: null,
      //   projectPaymentRatio: null,
      //   projectFundingDeadline: '',
      //   totalCount: null,
      //   administrativeApprovalCount: null,
      //   implementationPlanApprovalCount: null,
      //   completionCount: null,
      //   beautifulHomesteadProjectsCount: null,
      //   industrySupportProjectsCount: null,
      //   employmentTrainingParticipants: null,
      //   otherProjectsCount: null,
      //   smallWaterConservationProjectsCount: null,
      //   safeDrinkingWaterProjectsCount: null,
      //   completionAcceptanceCount: null,
      //   completionInvestment: null,
      //   completionInvestmentRatio: null,
      //   remarks: ''
      // }
      // this.listData.unshift(obj)
      // this.getBasicInfo()
      this.title = '新增'
    },
    view(type, row) {
      this.type = type
      this.row = row

      this.title = type == 'view' ? '详情' : '查看统计表'
      this.getBasicInfo()
    },
    editCols() {
      this.type = 'edit'
      this.title = '编辑'
      this.$nextTick(() => {
        this.$refs.table.doLayout()
      })
    },
    btnClose() {
      this.$emit('handleBack')
    },
    addCols() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          let obj = {
            year: this.searchObj.year, //年份
            countyId: null, //县（市区）
            county: '', //县（市区）
            orderNo: null,
            totalFunding: null,
            directSubsidyFunding: null,
            projectFundingAllocation: null,
            projectFundingPayment: null,
            projectPaymentRatio: null,
            projectFundingDeadline: '',
            totalCount: null,
            administrativeApprovalCount: null,
            implementationPlanApprovalCount: null,
            completionCount: null,
            beautifulHomesteadProjectsCount: null,
            industrySupportProjectsCount: null,
            employmentTrainingParticipants: null,
            otherProjectsCount: null,
            smallWaterConservationProjectsCount: null,
            safeDrinkingWaterProjectsCount: null,
            completionAcceptanceCount: null,
            completionInvestment: null,
            completionInvestmentRatio: null,
            remarks: ''
          }

          // if (this.listData.length) {
          //   let flag = this.listData.every(ele => ele.countyId)
          //   if (flag) {
          //     this.listData.splice(this.listData.length, 0, obj)
          //   } else {
          //     this.$message.error('请选择县（市区）')
          //   }
          // } else {
          //   this.listData.splice(this.listData.length, 0, obj)
          // }
          this.listData.splice(this.listData.length, 0, obj)
        }
      })
    },
    removeCol(index) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.listData.splice(index, 1)
          this.$message.success('删除成功')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    formatRate(name, index) {
      let sum = 0
      // 定义一个变量保存上一次的sum值
      let prevSum = 0
      // 遍历listData数组

      let ele = this.listData[index]
      // 当前sum值计算
      let currentSum = 0
      if (name == 'projectPaymentRatio') {
        currentSum = (Number(ele['projectFundingPayment']) / Number(ele['projectFundingAllocation'])) * 100
      } else if (name == 'completionInvestmentRatio') {
        currentSum = (Number(ele['completionInvestment']) / Number(ele['totalFunding'])) * 100
      }
      // 如果当前sum值为NaN或Infinity，则使用上一次的sum值
      if (Number.isNaN(currentSum) || isFinite(currentSum)) {
        sum = Number.isNaN(currentSum) ? 0 : currentSum
      } else {
        sum = prevSum
      }
      // 更新上一次的sum值
      prevSum = sum

      this.listData[index][name] = prevSum.toFixed(2) / 100
      return prevSum.toFixed(2) + '%'
    },

    refreshTable() {
      this.getBasicInfo()
    },
    getBasicInfo() {
      let params = {
        id: this.row.id
      }
      _ProgressReportInfo(params).then(res => {
        if (res.code == 200) {
          this.listData = [res.data]
          // let obj = {
          //   year: this.searchObj.year, //年份
          //   countyId: null, //县（市区）
          //   county: '总计', //县（市区）
          //   orderNo: null,
          //   totalFunding: null,
          //   directSubsidyFunding: null,
          //   projectFundingAllocation: null,
          //   projectFundingPayment: null,
          //   projectPaymentRatio: null,
          //   projectFundingDeadline: '',
          //   totalCount: null,
          //   administrativeApprovalCount: null,
          //   implementationPlanApprovalCount: null,
          //   completionCount: null,
          //   beautifulHomesteadProjectsCount: null,
          //   industrySupportProjectsCount: null,
          //   employmentTrainingParticipants: null,
          //   otherProjectsCount: null,
          //   smallWaterConservationProjectsCount: null,
          //   safeDrinkingWaterProjectsCount: null,
          //   completionAcceptanceCount: null,
          //   completionInvestment: null,
          //   completionInvestmentRatio: null,
          //   remarks: ''
          // }
          // this.listData.unshift(obj)
          console.log(this.listData, '========获取详情==========')
        }
      })
    },
    submitForm() {
      let listData = this.listData.map(ele => {
        return {
          ...ele,
          totalCount: Number(ele.administrativeApprovalCount) + Number(ele.implementationPlanApprovalCount)
        }
      })
      console.log(listData, '===submitForm===')
      let flag = listData.every(ele => ele.countyId)

      switch (this.type) {
        case 'add':
          if (flag) {
            _ProgressReportAdd(listData).then(res => {
              if (res.code == 200) {
                this.$message.success('添加成功')
                this.btnClose()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择行政区域')
          }
          break
        case 'edit':
          if (flag) {
            _ProgressReportEdit(listData[0]).then(res => {
              if (res.code == 200) {
                this.type = 'view'
                this.$message.success('修改成功')
                this.getBasicInfo()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择行政区域')
          }

          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
@import '@/common/scss/formview.scss';

::v-deep .el-col-17 {
  margin-left: -1px;
}
</style>
